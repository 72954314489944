import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import calendarReducer from './slices/calendar';
import clientReducer from './slices/client';
import applicationReducer from './slices/application';
import mediaReducer from './slices/media';
import viewReducer from './slices/view';
import componentReducer from './slices/component';
import itemReducer from './slices/item';
import stripeReducer from './slices/stripe';
import notificationReducer from './slices/notification';
import RewardReducer from './slices/rewards';
import analyticsReducer from './slices/analytics';
import chatReducer from './slices/chat';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-'
  // whitelist: ['client']
};

const rootReducer = combineReducers({
  component: componentReducer,
  stripe: stripeReducer,
  user: userReducer,
  view: viewReducer,
  item: itemReducer,
  calendar: calendarReducer,
  client: clientReducer,
  application: applicationReducer,
  media: mediaReducer,
  notification: notificationReducer,
  reward: RewardReducer,
  analytics: analyticsReducer,
  chat: chatReducer
});

export { rootPersistConfig, rootReducer };
