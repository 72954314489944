import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { firebaseConfig } from '../config';

// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const docRef = firebase.firestore().collection('users').doc(user.uid);
          docRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                const { roles } = doc.data();
                const isRoot = roles.includes('root');
                const isAdmin = roles.includes('admin');
                const isClientAdmin = roles.includes('client_admin');
                setProfile({
                  ...doc.data(),
                  userRoles: { root: isRoot, admin: isAdmin, client_admin: isClientAdmin }
                });
              }
            })
            .catch((error) => {
              console.error(error);
            });

          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );

  const login = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const register = (email, password, firstName, lastName) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection('users')
          .doc(res.user.uid)
          .set({
            uid: res.user.uid,
            email,
            displayName: `${firstName} ${lastName}`,
            roles: ['user'],
            activeClient: {},
            emailVerified: false,
            disabled: false
          });
      });

  const logout = async () => {
    await firebase.auth().signOut();
    setProfile(null);
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const changePassword = async (email, currentPassword, newPassword) => {
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(email, currentPassword);
      await firebase.auth().currentUser.reauthenticateWithCredential(credential);
      await firebase.auth().currentUser.updatePassword(newPassword);
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  // const updateProfilePhoto = async (payload) => {
  //   setProfile({ ...profile, photoURL: payload });
  // };

  const updateProfile = async (payload) => {
    await firebase
      .auth()
      .currentUser.updateProfile({ payload })
      .then(() => {
        const { email, phoneNumber, displayName } = payload;
        firebase
          .firestore()
          .collection('users')
          .doc(firebase.auth().currentUser.uid)
          .set({ displayName, email, phoneNumber }, { merge: true });
      });
  };

  const updateEmail = async (email) => {
    await firebase
      .auth()
      .currentUser.updateEmail(email)
      .then(() => {
        firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).set({ email }, { merge: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const auth = { ...state.user };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: auth.uid,
          email: auth.email,
          photoURL: auth.photoURL || profile?.photoURL,
          displayName: auth.displayName || profile?.displayName,
          roles: profile?.roles || [],
          phoneNumber: auth.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          disabled: auth.disabled || profile?.disabled,
          emailVerified: auth.emailVerified || profile?.emailVerified,
          activeClient: profile?.activeClient
        },
        userRoles: { ...profile?.userRoles } || { root: false, admin: false, client_admin: false },

        login,
        register,
        logout,
        resetPassword,
        updateProfile,
        updateEmail,
        changePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
