import { capitalCase } from 'change-case';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, Stack, Container, Tooltip, Typography, Button, Alert } from '@mui/material';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Logo from 'components/Logo/Logo';
import Page from 'components/Page';
import { LoginForm } from 'components/authentication/login';
import { SITE_CONFIG } from 'config';
// import AuthFirebaseSocials from 'components/authentication/AuthFirebaseSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
  marginBottom: 10
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 0)
}));
const LoginContainer = styled(Card)(({ theme }) => ({
  width: '100%',
  // maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2, 3, 5, 3),
  marginBottom: 10
}));
// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();
  const theme = useTheme();
  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title={`Login | ${SITE_CONFIG().APPLICATION_NAME} Content Portal`}>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack flex flexDirection="row" alignItems="center" justifyContent="center" sx={{ p: 2, mb: 1 }}>
            <Logo height={55} />
          </Stack>
          <SectionStyle>
            <Stack direction="row" alignItems="center">
              <Box sx={{ flexGrow: 1, color: 'rgb(160, 174, 192)' }}>
                <Typography sx={{ fontSize: '0.75rem' }}> Secure Google Firebase Authentication</Typography>
              </Box>
              <Tooltip title={capitalCase(method)}>
                <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
              </Tooltip>
            </Stack>
          </SectionStyle>
          <LoginContainer>
            <Stack direction="column" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="h3" gutterBottom>
                Log in
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {`Sign in to ${SITE_CONFIG().APPLICATION_NAME} the internal platform`}
              </Typography>
            </Stack>
            <Alert severity="info" sx={{ mb: 3 }}>
              Use <strong>email</strong> and <strong>password</strong>
              {` registered on ${SITE_CONFIG().APPLICATION_NAME} mobile app.`}
            </Alert>
            {method !== 'auth0' ? (
              <LoginForm />
            ) : (
              <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
                Login
              </Button>
            )}

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Having trouble?&nbsp;
              <br />
              Contact&nbsp;<span style={{ color: theme.palette.primary.main }}>app.support@giantkiller.com</span> for
              help signing in.
            </Typography>

            {/* <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography> */}
          </LoginContainer>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
