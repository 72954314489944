import { reject, cloneDeep, omit } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// Firebase
import { db, storage } from '../../config/firebase';

// ----------------------------------------------------------------------

const initialState = {
  isAppIconLoading: {},
  isLoading: false,
  error: false,
  applicationList: [],
  currentApplication: null
};

const slice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // LOADING APP ICON
    startIconLoading(state, action) {
      state.isAppIconLoading = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET APPLICATION LIST
    getApplicationListSuccess(state, action) {
      state.isLoading = false;
      state.applicationList = action.payload;
    },

    // GET APPLICATION
    getApplicationSuccess(state, action) {
      state.isLoading = false;
      state.currentApplication = action.payload;
    },

    // CREATE APPLICATION
    getCreateApplicationSuccess(state, action) {
      state.isLoading = false;
      state.applicationList = [...state.applicationList, action.payload];
    },

    // UPDATE APPLICATION
    getUpdateApplicationSuccess(state, action) {
      const { id } = action.payload;
      state.isLoading = false;
      state.isAppIconLoading = omit(state.isAppIconLoading, [id]);
      const appIndex = state.applicationList.findIndex((app) => app.id === id);
      state.applicationList[appIndex] = action.payload;
    },

    // DELETE APPLICATION
    deleteApplication(state, action) {
      state.applications = reject(state.application, { id: action.payload });
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteApplication } = slice.actions;

// ----------------------------------------------------------------------

export function getApplicationList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = [];
      const _applications = await db.collection('applications').get();
      _applications.forEach((doc) => {
        response.push(doc.data());
      });
      dispatch(slice.actions.getApplicationListSuccess(response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getApplication(appId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const _application = await db.collection('applications').doc(appId).get();
      dispatch(slice.actions.getApplicationSuccess(_application.data()));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createApplication(newApplication) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const appRef = db.collection('applications').doc();
    const file = newApplication?.appIconUrl?.file;

    try {
      const firebaseObject = {
        id: appRef.id,
        displayName: newApplication.displayName,
        bundleId: newApplication.bundleId,
        isContainer: newApplication.isContainer,
        theme: {
          colors: {
            primaryColor: newApplication.primaryColor,
            secondaryColor: newApplication.secondaryColor
          }
        }
      };
      // If File Begin Upload
      if (file) {
        const storageRef = storage.ref();
        const extension = file.type.split('/')[1];
        const uploadTask = storageRef.child(`applications/app_icon_${appRef.id}.${extension}`).put(file);
        uploadTask.on(
          'state_changed',
          () => {},
          (error) => {
            console.log(error);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              firebaseObject.appIconUrl = downloadURL;
              appRef.set(firebaseObject, { merge: true });
              dispatch(slice.actions.getCreateApplicationSuccess(firebaseObject));
            });
          }
        );
      } else {
        firebaseObject.appIconUrl = newApplication.appIconUrl;
        await appRef.set(firebaseObject);
        dispatch(slice.actions.getCreateApplicationSuccess(firebaseObject));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function updateApplication(updatedValues) {
  return async (dispatch, getState) => {
    const { application } = cloneDeep(getState());
    dispatch(slice.actions.startLoading());

    try {
      const { id } = updatedValues;
      application.isAppIconLoading[id] = id;
      dispatch(slice.actions.startIconLoading(application.isAppIconLoading));

      const appRef = db.collection('applications').doc(id);

      const firebaseObject = {
        id,
        displayName: updatedValues.displayName,
        bundleId: updatedValues.bundleId,
        isContainer: updatedValues.isContainer,
        theme: {
          colors: {
            primaryColor: updatedValues.primaryColor,
            secondaryColor: updatedValues.secondaryColor
          }
        }
      };
      const { file } = updatedValues.appIconUrl;

      // If File Begin Upload
      if (file) {
        const storageRef = storage.ref();
        const extension = file.type.split('/')[1];
        const uploadTask = storageRef.child(`applications/app_icon_${id}.${extension}`).put(file);
        uploadTask.on(
          'state_changed',
          () => {},
          (error) => {
            console.log(error);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
              firebaseObject.appIconUrl = downloadURL;
              console.log(firebaseObject);

              await appRef.set(firebaseObject, { merge: true });
              dispatch(slice.actions.getUpdateApplicationSuccess(firebaseObject));
            });
          }
        );
      } else {
        firebaseObject.appIconUrl = updatedValues.appIconUrl;
        console.log(firebaseObject);
        await appRef.set(firebaseObject, { merge: true });
        dispatch(slice.actions.getUpdateApplicationSuccess(firebaseObject));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}
