import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoMyVfw.propTypes = {
  sx: PropTypes.object,
  viewBox: PropTypes.string
};

export default function LogoMyVfw({ sx, viewBox = '0 0 431.51 190.52' }) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      sx={{
        width: 200,
        maxHeight: 'auto',
        cursor: 'pointer',
        ...sx
      }}
    >
      <svg preserveAspectRatio="xMidYMid meet" x="0" y="0" width="200" height="64" viewBox={viewBox}>
        <path
          d="M354.65,205.52h12c7.17,0,14.33,0,21.5,0a2.28,2.28,0,0,1,2.65,1.89q3.46,13,7,26.05c2.62,9.66,5.28,19.31,8.41,29l5.23-14.32q5.69-15.51,11.37-31c1.22-3.34,2.34-6.72,3.68-10a2.84,2.84,0,0,1,2-1.49q13.75-.15,27.49,0a2.3,2.3,0,0,1,1.78,1.13c3.47,9.31,6.85,18.65,10.24,28s6.75,18.5,10.4,28.52c5.36-19.66,10.52-38.61,15.69-57.58h35.79c-10,34.32-20,68.46-30.09,102.82h-2.15c-11.5,0-23,0-34.49,0a2.48,2.48,0,0,1-2.83-2c-5.12-14.34-10.33-28.64-15.53-42.95-.78-2.17-1.62-4.31-2.63-7-2,5.42-3.89,10.33-5.68,15.28q-6.28,17.26-12.46,34.56a2.71,2.71,0,0,1-3.09,2.06q-17.11-.12-34.24,0c-1.68,0-2.37-.41-2.87-2.16-5.58-19.54-11.28-39.05-17-58.55-4-13.79-8.11-27.57-12.15-41.35A4.74,4.74,0,0,1,354.65,205.52Z"
          transform="translate(-98.4 -117.97)"
          fill={PRIMARY_DARK}
        />
        <path
          d="M293.42,277.26v31H257.55V205.68h95.73v31.73H293.61v12.78h59.61v27.07Z"
          transform="translate(-98.4 -117.97)"
          fill={PRIMARY_DARK}
        />
        <path
          d="M153.5,205.63h36.15c5,18.86,10.07,37.81,15.31,57.52,5.22-19.59,10.27-38.51,15.34-57.53h36.13q-15.06,51.5-30.07,102.76H183.59Z"
          transform="translate(-98.4 -117.97)"
          fill={PRIMARY_DARK}
        />
        <path
          d="M98.45,196.29c2-26.16,3.92-52.13,5.88-78.18h17.56c7.42,15.71,14.85,31.42,22.49,47.58.59-1.07,1-1.78,1.39-2.53,6.76-14.29,13.54-28.57,20.22-42.89.76-1.64,1.59-2.35,3.49-2.3,5,.16,10,0,15.13,0,2,26.19,3.92,52.16,5.89,78.3h-19q-1.16-21.27-2.31-42.4l-.52-.18c-.39.74-.8,1.47-1.16,2.21-4.19,8.6-8.44,17.18-12.51,25.83a3.42,3.42,0,0,1-3.78,2.29c-4.58-.15-9.17-.13-13.74,0a3.14,3.14,0,0,1-3.41-2.08c-4.1-8.64-8.33-17.22-12.52-25.82a6,6,0,0,0-1.76-2.32c-.77,14.09-1.54,28.18-2.31,42.45Z"
          transform="translate(-98.4 -117.97)"
          fill={PRIMARY_DARK}
        />
        <path
          d="M174.74,307.75c-1.09.08-1.89.19-2.69.19-6,0-12-.06-18,0-1.87,0-2.81-.52-3.36-2.4q-11-38-22.12-75.87-3.26-11.13-6.54-22.24c-.13-.46-.22-.93-.38-1.58.82-.06,1.45-.15,2.09-.15,6.25,0,12.49,0,18.74,0,1.76,0,2.67.46,3.19,2.25q7.53,26.12,15.21,52.18,6.48,22.14,13,44.26C174.16,305.41,174.4,306.44,174.74,307.75Z"
          transform="translate(-98.4 -117.97)"
          fill={PRIMARY_DARK}
        />
        <path
          d="M240.08,196.35H220.85c0-.85-.13-1.72-.13-2.59,0-7.58.08-15.16-.07-22.74a13.5,13.5,0,0,0-1.35-5.67c-7.58-14.9-15.3-29.72-23-44.57-.41-.8-.8-1.61-1.37-2.75,6.77,0,13.22,0,19.67.09.64,0,1.47,1.14,1.83,1.92,4.33,9.45,8.58,18.93,12.85,28.4.27.61.55,1.21,1.05,2.3.54-1.08.95-1.81,1.29-2.56,4.25-9.39,8.52-18.77,12.69-28.2.62-1.4,1.27-2,2.89-2,6.07.11,12.15,0,18.61,0-.49,1-.84,1.82-1.24,2.59-7.78,15.08-15.6,30.13-23.32,45.24a11.17,11.17,0,0,0-1.15,4.72c-.11,7.66,0,15.32,0,23Z"
          transform="translate(-98.4 -117.97)"
          fill={PRIMARY_DARK}
        />
        <path
          d="M141.83,307.85c-4.48,0-8.61.11-12.73-.11-.62,0-1.42-1.49-1.7-2.42-3.52-11.88-7-23.78-10.43-35.68q-8.76-29.92-17.52-59.85c-.35-1.18-.63-2.39-1-4,4.47,0,8.67-.08,12.86.09.52,0,1.21,1.25,1.44,2,4,13.4,7.87,26.81,11.79,40.22q8.33,28.5,16.67,57C141.37,305.82,141.52,306.54,141.83,307.85Z"
          transform="translate(-98.4 -117.97)"
          fill={PRIMARY_DARK}
        />
      </svg>
    </Box>
  );
}
