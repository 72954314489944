// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  business: getIcon('ic_business'),
  roles: getIcon('ic_roles'),
  usergroups: getIcon('ic_usergroups'),
  usermanagement: getIcon('ic_usermanagement'),
  customlayout: getIcon('ic_customlayout'),
  layout: getIcon('ic_layout'),
  widgets: getIcon('ic_widgets'),
  insights: getIcon('ic_insights'),
  layers: getIcon('ic_layers'),
  apps: getIcon('ic_apps'),
  settings: getIcon('ic_settings'),
  billing: getIcon('ic_billing'),
  policy: getIcon('ic_policy'),
  security: getIcon('ic_security')
};

const sidebarConfig = [
  // ADMIN CONSOLE
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      // {
      //   title: 'overview',
      //   path: PATH_DASHBOARD.admin.overview,
      //   icon: ICONS.dashboard
      // },
      {
        title: 'applications',
        path: PATH_DASHBOARD.admin.applications,
        icon: ICONS.apps,
        roles: ['root', 'admin']
      },
      {
        title: 'clients',
        path: PATH_DASHBOARD.admin.clients,
        icon: ICONS.business
      }

      // { title: 'support chat', path: PATH_DASHBOARD.admin.supportChat, icon: ICONS.chat }
    ]
  },
  {
    subheader: 'User Administration',
    items: [
      {
        title: 'users',
        path: PATH_DASHBOARD.admin.globalUsers,
        icon: ICONS.usergroups,
        roles: ['root']
      }
      // {
      //   title: 'roles & permissions',
      //   path: PATH_DASHBOARD.admin.userRoles,
      //   icon: ICONS.roles,
      //   roles: ['root']
      // }
      // {
      //   title: 'user access',
      //   path: PATH_DASHBOARD.admin.userAccess,
      //   icon: ICONS.policy
      // }
    ],
    roles: ['root']
  },
  {
    subheader: 'Developer',
    items: [
      {
        title: 'migration tools',
        path: PATH_DASHBOARD.admin.developer,
        icon: ICONS.policy,
        roles: ['root']
      }
    ],
    roles: ['root', 'admin']
  }
];

export default sidebarConfig;
