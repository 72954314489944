import { cloneDeep } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// Firestore DB
import { db, arrayRemove, serverTimestamp } from 'config/firebase';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  componentList: []
  // currentComponent: null
};

const slice = createSlice({
  name: 'component',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COMPONENT LIST
    getComponentListSuccess(state, action) {
      state.isLoading = false;
      state.componentList = action.payload;
    },

    // GET COMPONENT
    getComponentSuccess(state, action) {
      state.isLoading = false;
      state.currentComponent = action.payload;
    },

    // DELETE COMPONENT
    getDeleteComponentSuccess(state, action) {
      const deletedComponentId = action.payload;
      state.isLoading = false;
      state.componentList = state.componentList.filter((component) => component.id !== deletedComponentId);
    },
    getUpdateComponentSuccess(state) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteApplication } = slice.actions;

// ----------------------------------------------------------------------

export function getComponentsList() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client } = cloneDeep(getState());

    try {
      const response = [];
      const clientRef = db.collection('clients').doc(client.currentClient.id);
      const componentsRef = clientRef.collection('components');

      const componentsList = await componentsRef.get();
      componentsList.forEach((doc) => {
        response.push(doc.data());
      });
      dispatch(slice.actions.getComponentListSuccess(response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getComponent(componentId, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const component = await db.collection('clients').doc(clientId).collection('components').doc(componentId).get();
      dispatch(slice.actions.getComponentSuccess(component.data()));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteComponent(component) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());
    const { id, icon, iconColor } = component;

    try {
      const clientRef = db.collection('clients').doc(client.currentClient.id);
      const sectionRef = clientRef.collection('sections');
      const viewRef = clientRef.collection('views');
      const componentRef = clientRef.collection('components').doc(id);

      // Check for Views Using Component
      const viewsInUse = await viewRef.where('componentIds', 'array-contains', id).get();
      const viewIds = [];
      viewsInUse.forEach((doc) => {
        viewIds.push(doc.data().id);
      });
      // Update View Docs
      if (viewIds.length > 0) {
        const viewReads = viewIds.map((viewId) =>
          viewRef.doc(viewId).set(
            {
              componentIcons: arrayRemove({ icon, iconColor }),
              componentIds: arrayRemove(id)
            },
            { merge: true }
          )
        );
        await Promise.all(viewReads);
      }

      // // Check for sectons Using Component
      const sectionsInUse = await sectionRef.where('componentIds', 'array-contains', id).get();
      const sectionIds = [];
      sectionsInUse.forEach((doc) => {
        sectionIds.push(doc.data().id);
      });
      // Update Section Docs
      if (sectionIds.length > 0) {
        const sectionReads = sectionIds.map((sectionId) =>
          sectionRef.doc(sectionId).set(
            {
              componentIds: arrayRemove(id)
            },
            { merge: true }
          )
        );
        await Promise.all(sectionReads);
      }
      // // Finally Delete Component
      await componentRef.delete();

      dispatch(slice.actions.getDeleteComponentSuccess(id));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateComponent(componentValues, componentId, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const componentRef = await db.collection('clients').doc(clientId).collection('components').doc(componentId);
      await componentRef.set({ ...componentValues, updatedAt: serverTimestamp() }, { merge: true });

      dispatch(slice.actions.getUpdateComponentSuccess());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
