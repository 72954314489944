import { cloneDeep } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// Firebase
import { db } from 'config/firebase';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  rewardsList: []
};

const slice = createSlice({
  name: 'reward',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REWARDS
    getRewardsSuccess(state, action) {
      state.isLoading = false;
      state.rewardsList = action.payload;
    },

    // UPDATE REWARD
    getRewardUpdateSuccess(state, action) {
      const updatedIndex = state.rewardsList.findIndex((reward) => reward.id === action.payload.id);
      state.isLoading = false;
      state.rewardsList[updatedIndex] = action.payload;
    },

    // CREATE REWARD
    getCreateRewardSuccess(state, action) {
      state.isLoading = false;
      state.rewardsList = [...state.rewardsList, action.payload];
    },

    // DELETE REWARD
    deleteRewardSuccess(state, action) {
      state.isLoading = false;
      state.rewardsList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRewardList(clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = [];
      const _rewards = await db.collection('clients').doc(clientId).collection('rewards').get();
      _rewards.forEach((doc) => {
        response.push(doc.data());
      });
      dispatch(slice.actions.getRewardsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function createReward(values, clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const rewardRef = db.collection('clients').doc(clientId).collection('rewards').doc();

    const firebaseObject = {
      id: rewardRef.id,
      clientId,
      ...values
    };
    try {
      await rewardRef.set(firebaseObject);
      dispatch(slice.actions.getCreateRewardSuccess(firebaseObject));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------
export function updateReward(values, clientId, rewardId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const rewardRef = db.collection('clients').doc(clientId).collection('rewards').doc(rewardId);
    const firebaseObject = {
      id: rewardId,
      ...values
    };
    try {
      await rewardRef.set(firebaseObject, { merge: true });
      console.log(firebaseObject);
      dispatch(slice.actions.getRewardUpdateSuccess(firebaseObject));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function deleteReward(clientId, rewardId) {
  return async (dispatch, getState) => {
    const { reward } = cloneDeep(getState());
    dispatch(slice.actions.startLoading());
    try {
      const filteredRewardsList = reward.rewardsList.filter((reward) => reward.id !== rewardId);
      await db.doc(`clients/${clientId}/rewards/${rewardId}`).delete();
      dispatch(slice.actions.deleteRewardSuccess(filteredRewardsList));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
