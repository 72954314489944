import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoMyelks.propTypes = {
  sx: PropTypes.object,
  viewBox: PropTypes.string
};

export default function LogoMyelks({ sx, viewBox = '0 0 459.84 348.97' }) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      sx={{
        width: 200,
        maxHeight: 'auto',
        cursor: 'pointer',
        ...sx
      }}
    >
      <svg preserveAspectRatio="xMidYMid meet" x="0" y="0" width="200" height="64" viewBox={viewBox}>
        <path
          d="M130.57,439.77c5-1.71,10.46-2.73,15-5.28,10.88-6.07,21.52-12.62,32-19.42,18.15-11.8,36.1-23.91,54.11-35.92,4.46-3,9-5.58,14.2-1.86,4.62,3.28,8,12.37,5.6,17.47a86,86,0,0,1-9.88,16.6c-3.63,4.7-8.28,8.67-12.77,12.63-30.66,27-56.94,58-81.26,90.66a7.84,7.84,0,0,0-1.63,4.67,29.24,29.24,0,0,0,4.16-1.58c15.92-9.05,31.76-18.27,47.76-27.17a48.06,48.06,0,0,1,12.47-4.83c13.13-3,22.94,2.1,28.53,14.23,4.49,9.75,1,18.9-8.51,23.81-24,12.31-47.86,24.8-71.77,37.23-4.87,2.52-9.56,5.44-14.59,7.58-9.68,4.13-18.67,2.38-26.32-4.75-4.73-4.41-9.1-9.25-13.4-14.1-4.86-5.49-5.71-11.76-3-18.62,8.06-20.45,20.27-38.15,35.43-53.93,1.54-1.61,3-3.36,4.42-5l-.62-1.26c-1.59.26-3.2.45-4.77.79-7.94,1.72-15.82,4.59-23.83,5-18.47.94-31.13-14.28-27.27-32.37a84.43,84.43,0,0,1,7-19.05c11.58-23.79,30.1-42.29,48.28-60.8,42.64-43.39,85.8-86.27,128.77-129.33,3.53-3.54,7-7.12,10.86-10.33,5.3-4.47,12.11-3.9,16.25,1.6,6.36,8.45,9.35,18.26,5.06,28.16-3.91,9-8.54,18.46-15.19,25.49-15.32,16.19-31.87,31.24-48.28,46.37-33.72,31.08-66.76,62.82-97.48,96.91-2.89,3.21-5.54,6.66-8.13,10.13a26.65,26.65,0,0,0-2.26,4.61Z"
          transform="translate(-71.07 -221.82)"
          fill={PRIMARY_DARK}
        />
        <path
          d="M384.51,408.34a73.74,73.74,0,0,0,7-4.28c13.43-10.4,26.77-20.92,40.19-31.35a26.38,26.38,0,0,1,5.86-3.72c5.9-2.45,11.8.47,13.05,6.74.64,3.17.21,6.6,0,9.9s-.27,6.7-1.38,9.71c-1.31,3.54-2.86,7.85-5.7,9.84-17.84,12.51-36,24.57-54.21,36.56-6.8,4.48-14,8.35-21,12.56-1.38.83-2.59,1.95-4.75,3.59l45.83,32.59c6.58-11.42,17.32-13.88,28.73-14.41,11.18-.52,20.29-5.31,28.75-13.53a76.85,76.85,0,0,0-8.16-1.39c-4.48-.28-9,0-13.48-.3C434,460.24,425.43,444,431.7,434.32c18-27.67,40.61-49.7,71.56-62.19,12.56-5.07,24,.09,27.06,11.37,1.49,5.57.19,10.39-4.22,13.94-3.07,2.48-6.65,4.33-10.08,6.35-13.75,8.07-27.61,15.94-41.21,24.25-3,1.85-5.15,5.19-7.68,7.84.27.52.54,1.05.82,1.57a39.84,39.84,0,0,0,5.55.33c2.32-.15,4.6-.76,6.91-1,7.71-.89,14.81.24,19.32,7.41,4.72,7.5,6.33,15.57,1.52,23.58a54.93,54.93,0,0,1-9.76,12.51c-18,16.35-38.84,27.92-61.86,35.63a11.84,11.84,0,0,1-4.84,1c-8.94-1.1-15.91,3.66-23.09,7.65-3.88,2.16-7.4,1.8-10.66-.8-6.77-5.4-13.43-10.93-20.06-16.49s-13.41-11.48-20.16-17.16c-3.89-3.27-5.43-3-8.17,1.4-2.38,3.81-4.59,7.73-6.9,11.58Q327,517.68,314,506.59c-8.4-7.19-9.84-12.86-5.7-22.89,1.77-4.31,3.81-8.51,5.42-12.88a17.77,17.77,0,0,0,1.32-7.2c-.41-5,1.76-8.41,5.16-11.81s6.66-7,8.69-11.21c19.94-41.2,39.61-82.52,59.35-123.81q8.38-17.57,16.69-35.17c3.08-6.54,8.41-10.67,14.67-13.7,5-2.4,8.43-.71,10.92,4.18a40.18,40.18,0,0,0,5.65,7.93c4.28,4.91,4.26,10.39,2.1,15.91-2,5.09-4.63,9.95-7.1,14.85q-22.93,45.48-45.9,90.93a44.35,44.35,0,0,0-2,5.65Z"
          transform="translate(-71.07 -221.82)"
          fill={PRIMARY_DARK}
        />
        <path
          d="M371.6,275.73c-.76,2.54-1.52,6.7-3.2,10.45q-44.34,99.39-88.93,198.64a104.87,104.87,0,0,1-8.15,14.31c-4.43,6.74-9.21,7.33-15.4,2.33-.64-.52-1.28-1.06-1.9-1.62-12.56-11.24-13.79-14.82-7.32-30.27,11.7-27.93,23.89-55.65,35.95-83.42q25.65-59.07,51.47-118a143.91,143.91,0,0,1,9-16.68c2.49-4.1,6.38-5,10.89-3.2C363.37,251.94,371.69,264.13,371.6,275.73Z"
          transform="translate(-71.07 -221.82)"
          fill={PRIMARY_DARK}
        />
        <path
          d="M144.82,291.51H129.94q-1-16.63-2-33.21l-.9-.36-11.58,23.5a4,4,0,0,1-.62.17c-13.86.93-13.87.93-20-11.42a73.81,73.81,0,0,0-6.89-12c-.67,10.95-1.33,21.9-2,33.21H71.07C72.63,270.67,74.2,250,75.79,229c4.22,0,8-.29,11.8.16,1.32.16,2.86,1.91,3.55,3.31,4.76,9.71,9.31,19.51,13.94,29.28.76,1.6,1.57,3.17,2.88,5.79,4.61-9.63,8.72-18.24,12.84-26.85.21-.45.39-.92.6-1.37,5.34-11.42,5.34-11.42,18.71-9.89C141.67,249.92,143.24,270.57,144.82,291.51Z"
          transform="translate(-71.07 -221.82)"
          fill={PRIMARY_DARK}
        />
        <path
          d="M184.62,291.54h-16.2c0-5.6.32-11.21-.12-16.75a30.54,30.54,0,0,0-2.64-10.87c-5.6-11.49-11.65-22.76-18-35,5.38,0,10.1-.17,14.79.14,1,.07,2.22,1.72,2.79,2.91,2.93,6.15,5.67,12.4,8.51,18.6.6,1.29,1.32,2.53,2.41,4.6,3.64-8,7-15.19,10.1-22.48,1.2-2.83,2.68-4.14,5.9-3.87,3.74.32,7.54.07,12.16.07-3.76,7.31-6.78,14.15-10.64,20.46C185.87,262.24,180.75,275.58,184.62,291.54Z"
          transform="translate(-71.07 -221.82)"
          fill={PRIMARY_DARK}
        />
      </svg>
    </Box>
  );
}
