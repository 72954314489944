import { LogoDefault, LogoMymoose, LogoMyelks, LogoMyfoe, LogoMyVfw, LogoMyScottsdale } from './index';

export default function Logo() {
  const containerId = process.env.REACT_APP_CONTAINER_ID;
  switch (containerId) {
    case 'MYELKS':
      return <LogoMyelks />;
    case 'MYMOOSE':
      return <LogoMymoose />;
    case 'MYVFW':
      return <LogoMyVfw />;
    case 'MYFOE':
      return <LogoMyfoe />;
    case 'MYLEGION':
      return <LogoDefault />;
    case 'MYSCOTTSDALE':
      return <LogoMyScottsdale />;
    default:
      return <LogoDefault />;
  }
}
