// redux
import { useSelector } from 'redux/store';
//
import { MAvatar } from 'components/@material-extend';
import createAvatar from 'utils/createAvatar';

// ----------------------------------------------------------------------

export default function ClientAvatar({ ...other }) {
  const { currentClient } = useSelector((state) => state.client);
  return currentClient && currentClient !== null ? (
    <MAvatar
      src={currentClient?.attributes?.logoUrl}
      alt={currentClient?.attributes?.name}
      color={currentClient?.attributes?.name ? 'default' : createAvatar(currentClient?.attributes?.name).color}
      {...other}
    >
      {createAvatar(currentClient.attributes.name).name}
    </MAvatar>
  ) : (
    <div />
  );
}
