import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
// routes
import { useNavigate } from 'react-router-dom';
// material
import { Container, Alert, AlertTitle, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node
};

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 0)
}));
// ----------------------------------------------------------------------

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [authorized, setAuthorized] = useState(null);

  useEffect(() => {
    if (!user?.roles) {
      setAuthorized(false);
    }
    if (user.roles) {
      const status = user.roles.filter((role) => accessibleRoles.indexOf(role) !== -1).length > 0;
      setAuthorized(status);
    }
  }, [user.roles, accessibleRoles]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  if (!authorized && authorized !== null) {
    return (
      <Container>
        <ContentStyle>
          <Alert severity="error">
            <AlertTitle>Permission Denied</AlertTitle>
            You do not have permission to access this page <br />
            Back to &nbsp;
            <Link onClick={() => handleLogout()}>login</Link>
          </Alert>
        </ContentStyle>
      </Container>
    );
  }

  return authorized !== null && <>{children}</>;
}
