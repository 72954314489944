import { cloneDeep, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// Firebase
import {
  arrayRemove,
  arrayUnion,
  fieldValue,
  db,
  serverTimestamp,
  storage,
  UPLOAD_STATE_CHANGED
} from 'config/firebase';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  clientList: [],
  globalClientList: [],
  client: null,
  currentClient: null,
  clientUsers: []
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USER CLIENTS
    getClientListSuccess(state, action) {
      state.isLoading = false;
      state.clientList = action.payload;
    },

    // GET ALL CLIENTS
    getGlobalClientListSuccess(state, action) {
      state.isLoading = false;
      state.globalClientList = action.payload;
    },

    // GET CLIENT
    getClientSuccess(state, action) {
      state.isLoading = false;
      state.currentClient = action.payload;
    },

    // GET CLIENT USERS
    getClientUsersSuccess(state, action) {
      state.isLoading = false;
      state.clientUsers = action.payload;
    },

    // ADD CLIENT USERS
    addClientUsersSuccess(state, action) {
      state.isLoading = false;
      state.clientUsers = [...state.clientUsers, action.payload];
      if (action.payload.role === 'owner') {
        state.currentClient.owner = action.payload.uid;
      } else {
        state.currentClient.editors = [...state.currentClient.editors, action.payload.uid];
      }
    },

    // DELETE CLIENT USERS
    deleteClientUsersSuccess(state, action) {
      state.isLoading = false;
      state.clientUsers = state.clientUsers.filter((user) => user.uid !== action.payload.uid);
      if (action.payload.role === 'owner') {
        state.currentClient.owner = '';
      } else {
        state.currentClient.editors = state.currentClient.editors.filter((uid) => uid !== action.payload.uid);
      }
    },

    // UPDATE CLIENT
    getClientUpdateSuccess(state) {
      state.isLoading = false;
    },

    // CREATE CLIENT
    getCreateClientSuccess(state) {
      state.isLoading = false;
    },
    getAssociationsListSuccess(state, action) {
      state.isLoading = false;
      state.assocations = action.payload;
    },
    // UPDATE CLIENT TEMPLATE
    getUpdateClientTemplate(state, action) {
      state.isLoading = false;
      const template = action.payload;
      state.currentClient.template = template;
    },
    // DELETE CLIENTS
    deleteClient(state, action) {
      const deleteClient = filter(state.clientList, (client) => client.id !== action.payload);
      state.clientList = deleteClient;
    },
    // RESET CURRENT CLIENT
    resetCurrentClient(state) {
      state.currentClient = null;
    },

    // AGOLIA USER PERMISSIONS
    setClientConfigSuccess(state, action) {
      state.isLoading = false;
      state.general = action.payload;
    },
    // RESET CLIENT SLUG
    clearClientConfig(state) {
      state.general = {
        appName: null,
        localName: null,
        slug: ''
      };
    },
    applyTargetUser(state, action) {
      state.targetUser = action.payload;
    },
    resetTargetUser(state) {
      state.targetUser = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { resetCurrentClient, onToggleFollow, deleteClient, applyTargetUser, resetTargetUser, clearClientConfig } =
  slice.actions;

// ----------------------------------------------------------------------

export function getGlobalClientList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = [];
      const _clients = await db.collection('clients').get();
      _clients.forEach((doc) => {
        response.push(doc.data());
      });
      dispatch(slice.actions.getGlobalClientListSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClientList(user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = [];
      if (user.roles.includes('root')) {
        const _clients = await db.collection('clients').get();
        _clients.forEach((doc) => {
          response.push(doc.data());
        });
      } else {
        const _ownerClients = await db.collection('clients').where('owner', '==', user.id).get();
        _ownerClients.forEach((doc) => {
          response.push(doc.data());
        });
        const _editorClients = await db.collection('clients').where('editors', 'array-contains', user.id).get();
        _editorClients.forEach((doc) => {
          response.push(doc.data());
        });
      }
      dispatch(slice.actions.getClientListSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getAssociationsList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = [];
      const _clients = await db.collection('clients').where('isStateAssociation', '==', true).get();
      _clients.forEach((doc) => {
        response.push(doc.data());
      });
      dispatch(slice.actions.getAssociationsListSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getClient(clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const _client = await db.collection('clients').doc(clientId).get();
      dispatch(slice.actions.getClientSuccess(_client.data()));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateClientTemplate(values) {
  return async (dispatch, getState) => {
    const { client } = cloneDeep(getState());
    dispatch(slice.actions.startLoading());
    const checkValues = (values) => {
      if (values === null) {
        return { id: null, name: null };
      }
      return values;
    };

    const { tabOneName, tabTwoName, tabThreeName, tabFourName, tabOneIcon, tabTwoIcon, tabThreeIcon, tabFourIcon } =
      values;
    const templateObj = {
      tabOne: { ...checkValues(values.tabOne), customName: tabOneName, icon: tabOneIcon },
      tabTwo: { ...checkValues(values.tabTwo), customName: tabTwoName, icon: tabTwoIcon },
      tabThree: { ...checkValues(values.tabThree), customName: tabThreeName, icon: tabThreeIcon },
      tabFour: { ...checkValues(values.tabFour), customName: tabFourName, icon: tabFourIcon }
    };

    try {
      const clientRef = await db.collection('clients').doc(client.currentClient.id);
      await clientRef.set({ template: { ...templateObj } }, { merge: true });
      dispatch(slice.actions.getUpdateClientTemplate({ ...templateObj }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function createClient(clientValues) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const clientRef = db.collection('clients').doc();
    console.log(clientValues);
    const { file } = clientValues?.logoUrl;

    const firebaseObject = {
      id: clientRef.id,
      applicationId: clientValues.applicationId,
      isActive: clientValues.isActive,
      isChatActive: clientValues.isChatActive,
      isStateAssociation: clientValues.isStateAssociation,
      stateAssociationId: clientValues.stateAssociationId,
      displayName: clientValues.name,
      attributes: {
        name: clientValues.name
      },
      locationImage: clientValues.locationImage,
      theme: {
        colors: {
          primaryColor: clientValues.primaryColor,
          secondaryColor: clientValues.secondaryColor
        }
      },
      template: {
        tabOne: null,
        tabTwo: null,
        tabThree: null,
        tabFour: null
      },
      previewLink: {
        clientShareRate: 10,
        dateLastShare: serverTimestamp(),
        numShares: 10
      }
    };
    try {
      // If File Begin Upload
      if (file) {
        const extension = file.type.split('/')[1];
        const storageRef = storage.ref(`clients/${clientRef.id}/logo_${clientRef.id}.${extension}`);
        const task = storageRef.put(file);
        task.on(UPLOAD_STATE_CHANGED, () => {
          task
            .then(() => storageRef.getDownloadURL())
            .then(async (downloadURL) => {
              firebaseObject.attributes.logoUrl = downloadURL;
              await clientRef.set(firebaseObject);
            });
        });
      }
      console.log(firebaseObject);
      dispatch(slice.actions.getCreateClientSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------
export function updateClient(clientId, updateClient) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const clientRef = db.collection('clients').doc(clientId);
    const { file } = updateClient.logoUrl;
    let firebaseObject = {};
    if (typeof updateClient.isStateAssociation !== 'undefined') {
      firebaseObject = {
        displayName: updateClient.name,
        isActive: updateClient.isActive,
        isChatActive: updateClient.isChatActive,
        stateAssociationId: updateClient.stateAssociationId,
        isStateAssociation: updateClient.isStateAssociation,
        locationImage: updateClient.locationImage,
        attributes: {
          name: updateClient.name
        },
        theme: {
          colors: {
            primaryColor: updateClient.primaryColor,
            secondaryColor: updateClient.secondaryColor
          }
        }
      };
    } else {
      firebaseObject = {
        displayName: updateClient.name,
        isActive: updateClient.isActive,
        isChatActive: updateClient.isChatActive,
        attributes: {
          name: updateClient.name
        },
        theme: {
          colors: {
            primaryColor: updateClient.primaryColor,
            secondaryColor: updateClient.secondaryColor
          }
        }
      };
    }
    try {
      // If File Begin Upload
      if (file) {
        const extension = file.type.split('/')[1];
        const storageRef = storage.ref(`clients/${clientId}/logo_${clientId}.${extension}`);
        const task = storageRef.put(file);
        task.on(UPLOAD_STATE_CHANGED, () => {
          task
            .then(() => storageRef.getDownloadURL())
            .then(async (downloadURL) => {
              firebaseObject.attributes.logoUrl = downloadURL;
              await clientRef.set(firebaseObject, { merge: true });
            });
        });
      } else {
        firebaseObject.attributes.logoUrl = updateClient.logoUrl;
        await clientRef.set(firebaseObject, { merge: true });
      }
      console.log(firebaseObject);
      dispatch(slice.actions.getClientUpdateSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------
export function getClientUsers(currentClient) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const _clientUsers = [];

      if (currentClient.editors) {
        const userReads = currentClient.editors.map(async (userId) => {
          const user = await db.doc(`users/${userId}`).get();
          if (user.exists) {
            const { displayName, photoURL, email } = user.data();
            _clientUsers.push({ displayName, photoURL, email, role: 'editor', uid: userId });
          }
        });
        await Promise.all(userReads);
      }
      if (currentClient.owner) {
        const user = await db.doc(`users/${currentClient.owner}`).get();
        if (user.exists) {
          const { displayName, photoURL, email } = user.data();
          _clientUsers.push({ displayName, photoURL, email, role: 'owner', uid: currentClient.owner });
        }
      }
      dispatch(slice.actions.getClientUsersSuccess(_clientUsers));
    } catch (error) {
      console.log(error);
    }
  };
}

export function addClientUsers(values, currentClient) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const clientRef = db.doc(`clients/${currentClient.id}`);
      const userRef = db.doc(`users/${values.uid}`);
      const batch = db.batch();
      if (values.role === 'owner') {
        await batch.set(clientRef, { owner: values.uid }, { merge: true });
        await batch.set(
          userRef,
          {
            roles: arrayUnion('admin')
          },
          { merge: true }
        );
        batch.commit();
        dispatch(slice.actions.addClientUsersSuccess(values));
      } else {
        await batch.set(clientRef, { editors: arrayUnion(values.uid) }, { merge: true });
        await batch.set(
          userRef,
          {
            roles: arrayUnion('admin')
          },
          { merge: true }
        );
        batch.commit();
        dispatch(slice.actions.addClientUsersSuccess(values));
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function deleteClientUsers(user, currentClient) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const clientRef = db.doc(`clients/${currentClient.id}`);
      const userRef = db.doc(`users/${user.uid}`);
      await userRef.get().then(async (doc) => {
        const { roles } = doc.data();
        if (user.role === 'owner') {
          await clientRef.update({ owner: fieldValue.delete() });
          const _ownerClients = await db.collection('clients').where('owner', '==', user.uid).get();
          const count = _ownerClients.size;
          if (count === 0 && !roles.includes('root')) {
            userRef.update(
              {
                roles: fieldValue.arrayRemove('admin')
              },
              { merge: true }
            );
          }
        } else {
          await clientRef.update({
            editors: arrayRemove(user.uid)
          });
          const _editorClients = await db.collection('clients').where('editors', 'array-contains', user.uid).get();
          const count = _editorClients.size;
          if (count === 0 && !roles.includes('root')) {
            userRef.update(
              {
                roles: fieldValue.arrayRemove('admin')
              },
              { merge: true }
            );
          }
        }
      });
      dispatch(slice.actions.deleteClientUsersSuccess(user));
    } catch (error) {
      console.log(error);
    }
  };
}
