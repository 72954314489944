import React, { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import AdminDashboardLayout from 'layouts/dashboard/admin';
import ClientDashboardLayout from 'layouts/dashboard/client';
import SettingsDashboardLayout from 'layouts/settings';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import NoLogoLayout from 'layouts/NoLogoLayout';
import MainLayout from 'layouts/main';
import LandingLayout from 'layouts/LandingLayout';
// guards
import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
import RoleBasedGuard from 'guards/RoleBasedGuard';
// components
import LoadingScreen from 'components/LoadingScreen';
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user, userRoles } = useAuth();

  // These values should exist before this page renders if it doesnt the user documnet might be corrupted
  const activeClient = user?.activeClient ? Object.values(user.activeClient)[0] : null;

  return useRoutes([
    // AUTH ROUTES
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'reset-password-mobile', element: <ResetPasswordMobile /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // ACCOUNT SETTINGS ROUTES
    {
      path: '/profile-settings',
      element: (
        <AuthGuard>
          <SettingsDashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/profile-settings/general" replace /> },
        { path: 'general', element: <ProfileSettings /> }
      ]
    },

    {
      path: 'dashboard',
      element:
        !userRoles?.admin && !userRoles?.client_admin ? (
          <LoadingScreen
            sx={{
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            }}
          />
        ) : (
          <Navigate
            to={
              userRoles?.client_admin && !userRoles?.admin
                ? `/dashboard/client-admin/overview/${activeClient}`
                : '/dashboard/admin/clients'
            }
            replace
          />
        )
    },

    // DASHBOARD ROUTES
    {
      path: 'dashboard/admin',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['admin']}>
            <AdminDashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),

      children: [
        { element: <Navigate to="/dashboard/admin/clients" replace /> },
        { path: 'overview', element: <AdminOverview /> },
        { path: 'applications', element: <Applications /> },
        { path: 'applications/new', element: <ApplicationCreate /> },
        { path: 'applications/:appId/edit', element: <ApplicationCreate /> },
        { path: 'clients', element: <ClientList /> },
        { path: 'clients/new', element: <ClientCreate /> },
        { path: 'clients/:clientId/edit', element: <ClientCreate /> },
        { path: 'global-users', element: <GlobalUsersList /> },
        { path: 'global-users/:uid/edit', element: <EmptyDashboardPage /> },
        { path: 'user-roles', element: <UserRoles /> },
        { path: 'user-access', element: <UserAccess /> },
        { path: 'developer', element: <MigrationTool /> }
      ]
    },

    // CLIENT DASHBOARD LAYOUT
    {
      path: 'dashboard/client-admin',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['root', 'admin', 'client_admin']}>
            <ClientDashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/client-admin/overview/:clientId" replace /> },
        { path: 'overview/:clientId', element: <ClientOverview /> },
        { path: 'account-settings/:clientId', element: <AccountSettings /> },
        { path: 'views/:clientId', element: <Views /> },
        { path: 'views/:clientId/new', element: <ViewCreate /> },
        { path: 'views/:clientId/view/:viewId/edit', element: <ViewCreate /> },
        { path: 'components/:clientId', element: <Components /> },
        { path: 'components/:clientId/component/:componentId/edit', element: <ComponentEdit /> },
        { path: 'items/:clientId', element: <Items /> },
        { path: 'items/:clientId/item/:itemId/edit', element: <ItemCreate /> },
        { path: 'item-groups/:clientId', element: <ItemGroups /> },
        { path: 'template/:clientId', element: <Template /> },
        { path: 'branding/:clientId', element: <EmptyDashboardPage /> },
        { path: 'user-analytics/:clientId', element: <EmptyDashboardPage /> },
        { path: 'notifications/:clientId', element: <Notifications /> },
        { path: 'events/:clientId', element: <Events /> },
        { path: 'media-library/:clientId', element: <MediaLibrary /> },
        { path: 'chat/:clientId', element: <Chat /> }
      ]
    },

    // Main Routes
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '*',
      element: <NoLogoLayout />,
      children: [
        { path: 'checkout', element: <Checkout /> },
        { path: 'checkout-success', element: <CheckoutSuccess /> },
        { path: 'checkout-error', element: <CheckoutError /> }
      ]
    },
    {
      path: '/landing/',
      element: <LandingLayout />,
      children: [
        { path: 'membership', element: <MembershipLandingPage /> },
        { path: 'vets', element: <VetsLandingPage /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: 'request-demo', element: <RequestDemoForm /> },
        { path: 'contact-us', element: <ContactSalesPage /> },
        { path: 'about', element: <AboutPage /> },
        { path: 'faqs', element: <FaqPage /> },
        { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
        { path: 'schedule-demo', element: <ScheduleDemoPage /> }
      ]
    },
    {
      path: '/list-your-business',
      element: <SignUp />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
// PLACEHOLDER PAGES
const EmptyDashboardPage = Loadable(lazy(() => import('../pages/EmptyDashboardPage')));

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ResetPasswordMobile = Loadable(lazy(() => import('../pages/authentication/ResetPasswordMobile')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// ADMIN DASHBOARD
const AdminOverview = Loadable(lazy(() => import('../pages/dashboard/admin/AdminOverview')));
const Applications = Loadable(lazy(() => import('../pages/dashboard/admin/Applications')));
const ApplicationCreate = Loadable(lazy(() => import('../pages/dashboard/admin/ApplicationCreate')));
const ClientList = Loadable(lazy(() => import('../pages/dashboard/admin/ClientList')));
const ClientCreate = Loadable(lazy(() => import('../pages/dashboard/admin/ClientCreate')));
const GlobalUsersList = Loadable(lazy(() => import('../pages/dashboard/admin/GlobalUsersList')));
const UserRoles = Loadable(lazy(() => import('../pages/dashboard/admin/UserRoles')));
const MigrationTool = Loadable(lazy(() => import('../pages/dashboard/admin/MigrationTool')));
const UserAccess = Loadable(lazy(() => import('../pages/dashboard/admin/UserAccess')));
// const UserEdit = Loadable(lazy(() => import('../pages/dashboard/admin/UserEdit')));

// CLIENT DASHBOARD
const ClientOverview = Loadable(lazy(() => import('../pages/dashboard/client/ClientOverview')));
const AccountSettings = Loadable(lazy(() => import('../pages/dashboard/client/AccountSettings')));
const Views = Loadable(lazy(() => import('../pages/dashboard/client/Views')));
const ViewCreate = Loadable(lazy(() => import('../pages/dashboard/client/ViewCreate')));
const Items = Loadable(lazy(() => import('../pages/dashboard/client/Items')));
const ItemGroups = Loadable(lazy(() => import('../pages/dashboard/client/ItemGroups')));
const ItemCreate = Loadable(lazy(() => import('../pages/dashboard/client/ItemCreate')));
const Components = Loadable(lazy(() => import('../pages/dashboard/client/Components')));
const ComponentEdit = Loadable(lazy(() => import('../pages/dashboard/client/ComponentEdit')));
const Template = Loadable(lazy(() => import('../pages/dashboard/client/Template')));
const Notifications = Loadable(lazy(() => import('../pages/dashboard/client/Notifications')));
const MediaLibrary = Loadable(lazy(() => import('../pages/dashboard/client/MediaLibrary')));
const Events = Loadable(lazy(() => import('../pages/dashboard/client/Events')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/client/Chat')));
// const MediaOverview = Loadable(lazy(() => import('../pages/dashboard/client/MediaOverview')));

// Main
const LandingPage = Loadable(lazy(() => import('pages/LandingPage')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ProfileSettings = Loadable(lazy(() => import('../pages/ProfileSettings')));
const AboutPage = Loadable(lazy(() => import('pages/AboutPage')));
const FaqPage = Loadable(lazy(() => import('pages/FaqPage')));
const PrivacyPolicyPage = Loadable(lazy(() => import('pages/PrivacyPolicyPage')));
const ScheduleDemoPage = Loadable(lazy(() => import('pages/ScheduleDemoPage')));
const RequestDemoForm = Loadable(lazy(() => import('pages/RequestDemo')));
const ContactSalesPage = Loadable(lazy(() => import('pages/ContactSalesPage')));
const MembershipLandingPage = Loadable(lazy(() => import('pages/MembershipLandingPage')));
const VetsLandingPage = Loadable(lazy(() => import('pages/VetsLandingPage')));
const SignUp = Loadable(lazy(() => import('pages/SignUpPage')));
const CheckoutSuccess = Loadable(lazy(() => import('pages/CheckoutSuccess')));
const Checkout = Loadable(lazy(() => import('pages/Checkout')));
const CheckoutError = Loadable(lazy(() => import('pages/CheckoutError')));
