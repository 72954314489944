// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PUBLIC = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  profileSettings: '/profile-settings'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  // ADMIN DASHBOARD PATHS
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    overview: path(ROOTS_DASHBOARD, '/admin/overview'),
    applications: path(ROOTS_DASHBOARD, '/admin/applications'),
    applicationCreate: path(ROOTS_DASHBOARD, '/admin/applications/new'),
    ApplicationEdit: path(ROOTS_DASHBOARD, '/admin/applications/:appId/edit'),
    clients: path(ROOTS_DASHBOARD, '/admin/clients'),
    clientCreate: path(ROOTS_DASHBOARD, '/admin/clients/new'),
    clientEdit: path(ROOTS_DASHBOARD, '/admin/clients'),
    supportChat: path(ROOTS_DASHBOARD, '/admin/support-chat'),
    globalUsers: path(ROOTS_DASHBOARD, '/admin/global-users'),
    developer: path(ROOTS_DASHBOARD, '/admin/developer'),
    userRoles: path(ROOTS_DASHBOARD, '/admin/user-roles'),
    userAccess: path(ROOTS_DASHBOARD, '/admin/user-access')
  },
  // CLIENT DASHBOARD PATHS
  client: {
    account: {
      overview: path(ROOTS_DASHBOARD, '/client-admin/overview'),
      accountSettings: path(ROOTS_DASHBOARD, '/client-admin/account-settings'),
      userAnalytics: path(ROOTS_DASHBOARD, '/client-admin/user-analytics')
    },
    engage: {
      notifications: path(ROOTS_DASHBOARD, '/client-admin/notifications'),
      events: path(ROOTS_DASHBOARD, '/client-admin/events'),
      items: path(ROOTS_DASHBOARD, '/client-admin/items'),
      itemGroups: path(ROOTS_DASHBOARD, '/client-admin/item-groups'),
      mediaLibrary: path(ROOTS_DASHBOARD, '/client-admin/media-library'),
      chat: path(ROOTS_DASHBOARD, '/client-admin/chat')
    },
    build: {
      views: path(ROOTS_DASHBOARD, '/client-admin/views'),
      components: path(ROOTS_DASHBOARD, '/client-admin/components')
    },
    design: {
      template: path(ROOTS_DASHBOARD, '/client-admin/template')
    }
  }
};
export const PATH_PUBLIC = {
  root: ROOTS_PUBLIC,
  public: {
    community: path(ROOTS_PUBLIC, '/community'),
    about: path(ROOTS_PUBLIC, '/about'),
    requestDemo: path(ROOTS_PUBLIC, '/get-started'),
    security: path(ROOTS_PUBLIC, '/security'),
    communication: path(ROOTS_PUBLIC, '/communication'),
    getStarted: path(ROOTS_PUBLIC, '/list-your-business'),
    faqs: path(ROOTS_PUBLIC, '/faqs')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
