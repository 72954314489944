import PropTypes from 'prop-types';
// material
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoDefault.propTypes = {
  sx: PropTypes.object
};

export default function LogoDefault({ sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 100, height: 100, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1981.67 1120.62">
        <path
          d="M602.72,439.2c6.95.54,13.9,1.16,20.85,1.62a644.76,644.76,0,0,1,140.89,24.92c66.28,19.6,127.25,49.48,180.66,93.86,5.27,4.38,10.34,9,15.5,13.52.64.56,1.25,1.15,2.2,2L865.86,714c-1.23-1-2.22-1.7-3.17-2.49-30-24.74-62-46.22-97.57-62.1-28.41-12.67-58.25-19.91-88.88-24.11-31.7-4.36-63.52-8-95.59-7C504,620.64,431.94,639.4,367,681.29,305.43,721,260.27,774.9,231.62,842.43c-18.66,44-28.21,89.84-29.57,137.62-.84,29.62-.09,59.09,3.84,88.44a384.47,384.47,0,0,0,21.26,85.29c21.3,56.45,55.47,103.76,101.19,142.69,48.78,41.52,104.55,68.82,167.86,80.37a464.37,464.37,0,0,0,119,6.5c62.34-4.76,122.8-17.62,179.37-45.31,10.87-5.32,21.08-12,31.7-17.8,3.4-1.87,4.53-4.21,4.52-8.14q-.19-142.44-.14-284.89v-6.13h182.18c0,1.23.19,2.74.19,4.25q.08,180.57.05,361.15a10.29,10.29,0,0,1-2.45,6.37,483.22,483.22,0,0,1-122.4,95.44c-56,30.58-115.9,49-178.47,59.71-49.67,8.53-99.68,12.81-150.08,11.64-149.15-3.43-278.47-55.72-386.6-158.71-62.57-59.59-106.55-131.3-134.25-213.05A554.56,554.56,0,0,1,9.46,1030.71a52.8,52.8,0,0,0-.73-5.43v-37.7c.27-1.68.64-3.35.78-5,1.29-15.49,2-31.06,3.89-46.48A593,593,0,0,1,43.15,809.36C67.56,740.56,100.64,677.22,152.3,624c61.37-63.22,130.12-114.95,212.48-147.46a523.22,523.22,0,0,1,189.64-36.61c4.11,0,8.2-.48,12.3-.73Z"
          fill="#333333"
          transform="translate(-8.73 -439.2)"
        />
        <path
          d="M1109.28,1018.62c1.32-1.4,2.35-2.55,3.44-3.64q53.07-53,106.14-106,188-187.29,376.18-374.48a9.7,9.7,0,0,1,6.22-2.57q108,.43,216,1.17c1.24,0,2.48.16,5,.33l-487,485.33L1821.08,1505h-4.73q-108.42-.32-216.83-.55a9.63,9.63,0,0,1-7.45-3.18Q1409,1317.79,1225.69,1134.43c-37.36-37.36-75-74.44-112.5-111.65C1111.9,1021.5,1110.7,1020.14,1109.28,1018.62Z"
          fill="#3366FF"
          transform="translate(-8.73 -439.2)"
        />
        <path
          d="M1964.66,1549.62c.56-1.55,1-2.82,1.48-4.1,4-11.44,8-22.87,11.87-34.34,1.78-5.25,5.89-1.31,9.2-3.26,1.1,17.72,2.14,34.6,3.2,51.76H1984c-.74-13.93-1.48-28-2.27-42.79a30.44,30.44,0,0,0-1.36,2.83c-4.2,12.12-8.29,24.28-12.65,36.34a6.55,6.55,0,0,1-3.79,3.51c-2.8.85-3-1.89-3.55-3.68-4.19-12.7-8.33-25.41-12.74-38.89-.84,14.64-1.64,28.59-2.45,42.73H1939c1.17-17,2.33-33.88,3.5-51H1951C1955.43,1522.06,1960,1535.61,1964.66,1549.62Z"
          transform="translate(-8.73 -439.2)"
        />
        <path d="M1932,1508.85v5.27h-15.24v45.51h-6.86v-45.35h-15.28v-5.43Z" transform="translate(-8.73 -439.2)" />
      </svg>
    </Box>
  );
}
