import { Icon } from '@iconify/react';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import instagramFill from '@iconify/icons-ant-design/instagram-filled';
import { Link as ScrollLink } from 'react-scroll';
// material
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
//

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', icon: facebookFill, link: 'https://www.facebook.com/profile.php?id=100092408695705' },
  { name: 'Instagram', icon: instagramFill, link: 'https://instagram.com/myscottsdaleapp' }
];

const LINKS = [
  {
    headline: 'My Scottsdale App',
    children: [
      { name: 'List Your Business', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact }
    ]
  },
  {
    headline: 'Contact',
    children: [
      { name: 'start@myscottsdaleapp.com', href: 'mailto:start@myscottsdale.com' },
      { name: '(800) 757-8234', href: 'tel:18007578234' }
    ]
  }
  // {
  //   headline: 'Legal',
  //   children: [
  //     { name: 'Terms and Condition', href: '#' },
  //     { name: 'Privacy Policy', href: '#' }
  //   ]
  // },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 5 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} justifyContent={{ xs: 'center', md: 'left' }} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth>
              <img alt="Logo" height={100} src="/static/home/logo-dark.png" />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              The ultimate app that brings together all of Scottsdale’s favorite local businesses right at your
              fingertips!
            </Typography>
            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 2, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <IconButton key={social.name} href={social.link} target="_blank" color="primary" sx={{ p: 1 }}>
                  <Icon icon={social.icon} width={16} height={16} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="left">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        href={link.href}
                        key={link.name}
                        target="_blank"
                        color="inherit"
                        variant="body2"
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2023 Giant Killer, LLC. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
