import { Icon } from '@iconify/react';

import moonFill from '@iconify/icons-eva/moon-fill';
import sunFill from '@iconify/icons-eva/sun-fill';

// hooks
import useSettings from '../../../hooks/useSettings';
import { MIconButton } from '../../../components/@material-extend';

// ----------------------------------------------------------------------

export default function ThemeModeButton() {
  const { themeMode, setThemeMode } = useSettings();

  const handleToggleMode = () => {
    if (themeMode === 'light') setThemeMode('dark');
    if (themeMode === 'dark') setThemeMode('light');
  };

  return (
    <MIconButton
      size="large"
      onClick={handleToggleMode}
      color={themeMode === 'dark' ? 'inherit' : 'default'}
      sx={{ bgcolor: 'action.hover' }}
    >
      <Icon icon={themeMode === 'light' ? sunFill : moonFill} width={20} height={20} />
    </MIconButton>
  );
}
