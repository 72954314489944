// import { cloneDeep } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
// Firebase
import config from '../../config/client-config.json';
import { db, functions } from '../../config/firebase';

const initialState = {
  isLoading: false,
  error: false,
  activeUsers: [],
  notifications: {},
  totalActiveUsers: null,
  totalVisitedUsers: 0,
  totalNotificationOpens: 0
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ACTIVE USERS
    getActiveUsersSuccess(state, action) {
      const { activeUsers, userRewards, totalCurPoints } = action.payload;
      state.isLoading = false;
      state.activeUsers = activeUsers;
      state.totalActiveUsers = activeUsers.length;
      state.userRewards = userRewards;
      state.totalCurPoints = totalCurPoints;
    },

    getRewardsDataSuccess(state, action) {
      const { totalRedeemedPoints, numRedeemedRewards } = action.payload;
      state.isLoading = false;
      state.totalRedeemedPoints = totalRedeemedPoints;
      state.numRedeemedRewards = numRedeemedRewards;
    },

    // GET VISITED USERS
    getVisitedUsersSuccess(state, action) {
      state.isLoading = false;
      state.totalVisitedUsers = action.payload.length;
    },

    // GET NOTIFICATIONS SUCCESS
    getNotificationAnalyticsSuccess(state, action) {
      state.isLoading = false;
      const { notificationStats, totalOpens } = action.payload;
      state.notifications = notificationStats;
      state.totalNotificationOpens = totalOpens;
    },

    // GET MONTHLY ACTIVE USERS
    getMonthlyActiveUsersSuccess(state, action) {
      state.isLoading = false;
      state.monthlyActiveUsers = action.payload;
    },
    // UPDATE USER REWARDS
    updateUserRewardsSuccess(state, action) {
      state.isLoading = false;
      state.userRewards = action.payload;
    },

    // RESET ANALYTICS
    resetAnalytics(state) {
      state.activeUsers = [];
      state.notifications = {};
      state.totalActiveUsers = 0;
      state.totalVisitedUsers = 0;
      state.totalNotificationOpens = 0;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { resetAnalytics } = slice.actions;

/**
 * Get Active Users
 */

export function getActiveUsers(clientId) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client } = cloneDeep(getState());
    const { currentClient } = client;

    try {
      const isStateAssociation = currentClient?.isStateAssociation || currentClient?.root;

      const activeUsers = [];
      const clientQuery = db.collection(`users`).where(`activeClient.${config.APPLICATION_ID}`, '==', clientId);
      const stateAssociationQuery = db.collection(`clientUsers`).where('clientId', '==', clientId);
      const fetchActiveUsers = isStateAssociation ? await stateAssociationQuery.get() : await clientQuery.get();
      const clientUsers = await db.collection('clientUsers').where('clientId', '==', clientId).get();

      const rewardsHash = {};
      clientUsers.docs.forEach((doc) => {
        const data = doc.data();
        rewardsHash[data?.userId] = data;
      });

      // add up CURRENT total rewards points for all members
      const totalCurPoints = Object.values(rewardsHash).reduce((acc, user) => acc + user?.rewards, 0);

      fetchActiveUsers.forEach((doc) => {
        activeUsers.push(doc.data());
      });
      dispatch(slice.actions.getActiveUsersSuccess({ activeUsers, userRewards: rewardsHash, totalCurPoints }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRewardsData(clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const curClientRedeemedRewards = await db.collection('userRewards').where('clientId', '==', clientId).get();
      const totalRedeemedPoints = curClientRedeemedRewards.docs.reduce(
        (acc, doc) => acc + doc.data().reward.pointValue,
        0
      );

      const numRedeemedRewards = curClientRedeemedRewards.docs.length;
      dispatch(slice.actions.getRewardsDataSuccess({ totalRedeemedPoints, numRedeemedRewards }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Get Visited Users
 */

export function getVisitedUsers(clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const clientUsers = [];
      const fetchClientUsers = await db.collection(`clientUsers`).where('clientId', '==', clientId).get();
      fetchClientUsers.forEach((doc) => {
        clientUsers.push(doc.data());
      });
      dispatch(slice.actions.getVisitedUsersSuccess(clientUsers));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Get Notification Stats
 */

export function getNotificationAnalytics(clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const notifications = [];
      const fetchNotifications = await db.collection(`notifications`).where('clientId', '==', clientId).get();
      fetchNotifications.forEach((doc) => {
        notifications.push(doc.data());
      });
      const notificationStats = {};
      let opens = 0;
      const notificationReads = notifications.map((notif) =>
        db
          .collection(`userNotifications`)
          .where(`opened`, 'array-contains', notif.id)
          .get()
          .then((doc) => {
            opens += doc.size;
            notificationStats[notif.id] = { ...notif, opens: doc.size };
          })
      );
      await Promise.all(notificationReads);
      dispatch(slice.actions.getNotificationAnalyticsSuccess({ notificationStats, totalOpens: opens }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Monthly Users Chart Data
 */

export function getMonthlyActiveUsers(clientId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await functions.httpsCallable('analytics-monthlyActiveUsers')({ clientId });
      // Functions doesn't go to the error case when functions throw httpErrorCode
      if (data.httpErrorCode) throw new Error(data.details.message);
      console.log(data);
      dispatch(slice.actions.getMonthlyActiveUsersSuccess());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateUserRewards(clientId, userId, selectedUserPoints) {
  return async (dispatch, getState) => {
    // dispatch(slice.actions.startLoading());
    const { analytics } = cloneDeep(getState());
    const { userRewards } = analytics;
    try {
      const clientUserId = `${clientId}_${userId}`;
      userRewards[userId] = { ...userRewards[userId], rewards: selectedUserPoints };
      await db.doc(`clientUsers/${clientUserId}`).set({ rewards: parseInt(selectedUserPoints, 10) }, { merge: true });
      dispatch(slice.actions.updateUserRewardsSuccess(userRewards));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
