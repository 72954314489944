import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

// Firebase
import { db, fieldValue, fromDate } from 'config/firebase';
import { formatDate } from 'utils/date-utils';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  notificationList: [],
  curNotification: null
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // IS SUCCESS
    isSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },

    // GET NOTIFICATION LIST
    getNotificationListSuccess(state, action) {
      state.isLoading = false;
      state.notificationList = action.payload;
    },

    // CREATE NOTIFICATION
    createNotificationSuccess(state) {
      state.isLoading = false;
    },

    // DELETE NOTIFICATION
    getDeleteNotificationSuccess(state) {
      state.isLoading = false;
    },

    // UPDATE NOTIFICATION
    getUpdateNotificationSuccess(state, action) {
      state.isLoading = false;
      state.curNotification = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getNotificationList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const notificationRef = db.collection('notifications');
    try {
      const notifications = await notificationRef.get();
      const notificationList = [];
      await notifications.forEach(async (notification) => {
        const data = notification.data();

        notificationList.push({ ...data, performAt: new Date(data?.performAt.seconds * 1000) });
      });
      dispatch(slice.actions.getNotificationListSuccess(notificationList));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createNotification(newNotif) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());
    const { currentClient } = client;
    try {
      const notificationRef = db.collection('notifications').doc();

      const { date, time, title, message, link, featuredImage } = newNotif;

      const timeZoneString = formatDate(new Date(date), 'ZZ');
      const notificationDate = new Date(`${date}T${time}:00.000${timeZoneString}`);

      const notificationObject = {
        status: 'scheduled',
        clientId: currentClient.id,
        id: notificationRef.id,
        message,
        title,
        link,
        featuredImage,
        performAt: fromDate(notificationDate)
      };
      await notificationRef.set({ ...notificationObject });

      dispatch(slice.actions.createNotificationSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function updateNotification(updatedNotification, curNotification) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { client } = cloneDeep(getState());
    const { currentClient } = client;

    const { date, time, title, message } = updatedNotification;

    const timeZoneString = formatDate(new Date(date), 'ZZ');
    const newNotifDate = new Date(`${date}T${time}:00.000${timeZoneString}`);

    const eventCollectionRef = db.collection('clients').doc(currentClient.id).collection('events');
    const eventRef = eventCollectionRef.doc(curNotification.eventId);
    const notificationRef = db.doc(`notifications/${curNotification.id}`);

    const fbNotificationObject = {
      ...updatedNotification,
      clientId: curNotification.clientId,
      status: curNotification.status,
      id: curNotification.id,
      performAt: fromDate(newNotifDate)
    };

    const fbEventObject = {
      title,
      notifDate: formatDate(newNotifDate, 'YYYY-MM-DD'),
      notifTime: formatDate(newNotifDate, 'HH:mm'),
      notifMessage: message
    };

    try {
      await notificationRef.set(fbNotificationObject, { merge: true });
      await eventRef.set(fbEventObject, { merge: true });
      dispatch(slice.actions.getUpdateNotificationSuccess(fbNotificationObject));
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function deleteNotification(notification) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());

    const { client } = cloneDeep(getState());
    const { currentClient } = client;

    try {
      // update event
      const collectionRef = db.collection('clients').doc(currentClient.id).collection('events');
      const _eventRef = await collectionRef.doc(notification.eventId).get();
      if (_eventRef.exists) {
        collectionRef.doc(notification.eventId).update({
          notification: false,
          notifDate: fieldValue.delete(),
          notifTime: fieldValue.delete(),
          notifMessage: fieldValue.delete()
        });
      }
      // deletes notification from collection
      await db.doc(`notifications/${notification.id}`).delete();
      dispatch(slice.actions.getDeleteNotificationSuccess());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
