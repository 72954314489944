import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/analytics';

import { firebaseConfig } from '../config';

const secondaryAppConfig = {
  apiKey: 'AIzaSyC90O5fZiwVNfA4sr_G3aO3xXFAwKBNngE',
  authDomain: 'gk-cms.firebaseapp.com',
  databaseURL: 'https://gk-cms-default-rtdb.firebaseio.com',
  projectId: 'gk-cms',
  storageBucket: 'gk-cms.appspot.com',
  messagingSenderId: '57970297786',
  appId: '1:57970297786:web:a32421cd1c7e5938b46b83',
  measurementId: 'G-TZ581TMJ37'
};

firebase.initializeApp(firebaseConfig());
export const firebaseApp = firebase.initializeApp(secondaryAppConfig, 'secondary');
firebase.storage();
firebase.performance();
firebase.analytics();
firebase.firestore();
export const db = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const UPLOAD_STATE_CHANGED = firebase.storage.TaskEvent.STATE_CHANGED;

// Firestore
/* eslint-disable-next-line */
export const arrayRemove = firebase.firestore.FieldValue.arrayRemove;
export const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const fromMillis = firebase.firestore.Timestamp.fromMillis;
export const fromDate = firebase.firestore.Timestamp.fromDate;
export const fieldValue = firebase.firestore.FieldValue;
export const increment = firebase.firestore.FieldValue.increment;

const env = process.env.REACT_APP_ENV;
if (env === 'development') {
  db.useEmulator('localhost', 8080);
  auth.useEmulator('http://localhost:9099');
  firebase.functions().useEmulator('localhost', 5001);
}

export { firebase };
