import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(utc);

/**
 *
 * @param {*} date
 * @returns
 *
 */
export function roundToNearestHalfHour(date) {
  const currentMinutes = date.getMinutes();
  if (currentMinutes < 30) {
    date.setMinutes(30);
  } else {
    date.setMinutes(60);
  }
  return date;
}

/**
 *
 * @param {*} date
 * @returns
 *
 */
export function roundToNextHour(date) {
  const currentHour = date.getHours();
  date.setHours(currentHour + 1);
  return date;
}

/**
 *
 * @param {*} date
 * @param {*} customFormat
 * @returns
 *
 */
export function formatDate(date, customFormat) {
  if (typeof date === 'number') {
    date = dayjs.unix(date);
  } else {
    date = dayjs(date);
  }
  let formatter = 'MMMM D, YYYY';
  if (customFormat) {
    formatter = customFormat;
  }
  return date.format(formatter);
}

/**
 *
 * @param {*} date
 * @returns
 *
 */
export function getUnix(date) {
  date = dayjs(date);
  return date.unix();
}

/**
 *
 * @param {*} timestamp
 * @returns formatted date from timestamp
 *
 */
export function formatTimestamp(timestamp, customFormat) {
  let formatting = 'YYYY-MM-DD HH:mm:ss';

  if (customFormat) {
    formatting = customFormat;
  }
  if (typeof timestamp !== 'number') {
    throw Error('MUST PASS UNIX TIMESTAMP');
  }
  const formatTimestamp = `${formatDate(timestamp, formatting)}`;
  return formatTimestamp;
}

/**
 *
 * @param {*} timestamp Unix timestamp
 * @param {*} timezone America/Phoenix
 */
export function convertTimestampToTimezone(timestamp, timezone, customFormat) {
  let formatter = 'YYYY-MM-DD HH:mm:ss';
  if (customFormat) {
    formatter = customFormat;
  }
  return dayjs(timestamp * 1000)
    .tz(timezone)
    .format(formatter);
}

/**
 *
 * @param {*} timestamp
 * @param {*} utcOffset
 * @returns
 */
export function getUtcOffset(timestamp, utcOffset) {
  // Checks if timestamp is unix and converts if not
  if (typeof timestamp !== 'number') {
    timestamp = dayjs(timestamp).unix();
  }
  const formatting = 'YYYY-MM-DD HH:mm:ss';

  const formattedDate = dayjs
    .utc(timestamp * 1000)
    .utcOffset(utcOffset)
    .format(formatting);

  return dayjs(formattedDate).unix();
}

export function formatTime(time, customFormat) {
  let formatter = 'h:mm A';
  if (customFormat) {
    formatter = customFormat;
  }
  time = time.split(':');
  const hour = time[0];
  const minute = time[1];
  const date = dayjs().hour(hour).minute(minute);

  return dayjs(date).format(formatter);
}

export function isDST(date) {
  const secondSundayMarch = new Date(date.getFullYear(), 2, 14 - date.getDay());
  const firstSundayNovember = new Date(date.getFullYear(), 10, 7 - date.getDay());
  return date > secondSundayMarch && date < firstSundayNovember;
}

// eslint-disable-next-line
export function mapTimezoneOffset(timezone) {
  switch (timezone) {
    // possible daylight savings stuff
    case 'America/New_York':
      return 'EDT';
    case 'America/Chicago':
      return 'CDT';
    case 'America/Phoenix':
      return 'MST';
    case 'America/Los_Angeles':
      return 'PDT';
    default:
  }
}
