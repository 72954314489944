// import { reject, cloneDeep, omit } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// Firebase
import { functions } from '../../config/firebase';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  prices: [],
  customerInvoices: [],
  priceDetails: {},
  setupIntent: undefined,
  wallet: [],
  activeSubscription: null
};

const slice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRICES LIST
    getPricesListSuccess(state, action) {
      state.isLoading = false;
      state.prices = action.payload;
    },

    // GET APPLICATION
    getPriceDetailsSuccess(state, action) {
      state.isLoading = false;
      state.priceDetails = action.payload;
    },

    // CREATE SETUP INTENT
    getCreateIntentSuccess(state, action) {
      state.isLoading = false;
      state.setupIntent = action.payload;
    },

    // CLEAR SETUP INTENT
    clearIntent(state) {
      state.setupIntent = undefined;
    },

    // GET WALLET
    getWalletSuccess(state, action) {
      const { primaryCard, secondaryCards } = action.payload;
      state.isLoading = false;
      state.wallet = secondaryCards;
      state.primaryPaymentMethod = primaryCard;
    },

    // CREATE SUBSCRIPTION
    getCreateSubscriptionSuccess(state, action) {
      state.isLoading = false;
      state.activeSubscription = action.payload;
    },

    // GET SUBSCRIPTION
    getSubscriptionSuccess(state, action) {
      state.isLoading = false;
      state.activeSubscription = action.payload;
    },

    // GET INVOICES
    getCustomerInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.customerInvoices = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { clearIntent } = slice.actions;

// ----------------------------------------------------------------------

export function getSubscription() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const request = functions.httpsCallable('stripe-listSubscriptions');
      const response = await request();
      dispatch(slice.actions.getSubscriptionSuccess(response.data.filter((sub) => sub.status === 'active')[0]));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createSubscription(stripeData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const request = functions.httpsCallable('stripe-createSubscription');
      const response = await request(stripeData);
      console.log(response.data);
      dispatch(slice.actions.getCreateSubscriptionSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateSubscription(stripeData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const request = functions.httpsCallable('stripe-updateSubscription');
      const response = await request(stripeData);
      dispatch(slice.actions.getCreateSubscriptionSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const request = functions.httpsCallable('stripe-listCustomerInvoices');
      const response = await request();
      dispatch(slice.actions.getCustomerInvoicesSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPricesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const request = functions.httpsCallable('stripe-pricesList');
      const response = await request({ options: { active: true } });
      const sortedPrices = response.data.sort((a, b) => a.amount - b.amount);
      dispatch(slice.actions.getPricesListSuccess(sortedPrices));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createSetupIntent() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const request = functions.httpsCallable('stripe-createSetupIntent');
      const response = await request();
      dispatch(slice.actions.getCreateIntentSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCustomerWallet() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const request = functions.httpsCallable('stripe-listPaymentMethods');
      const response = await request();

      const primaryCard = response.data.paymentMethods.find((card) => card.id === response.data.primary);
      const secondaryCards = response.data.paymentMethods.filter((card) => card.id !== response.data.primary);

      dispatch(slice.actions.getWalletSuccess({ primaryCard, secondaryCards }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function removePaymentMethod(PaymentMethod) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const request = functions.httpsCallable('stripe-detatchPaymentMethod');
      const response = await request({ PaymentMethod });
      console.log(response.data);
      // dispatch(slice.actions.getWalletSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
