import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// ----------------------------------------------------------------------

LogoMyfoe.propTypes = {
  sx: PropTypes.object,
  viewBox: PropTypes.string
  // height: PropTypes.number
};

export default function LogoMyfoe({ sx, viewBox = '0 0 306.75 201.49' }) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      sx={{
        width: 200,
        maxHeight: 'auto',
        cursor: 'pointer',
        ...sx
      }}
    >
      <svg preserveAspectRatio="xMidYMid meet" x="0" y="0" viewBox={viewBox} width="200" height="64">
        <path
          d="M431.24,415.79l-4.1-3.19,3.47-6.42c1.46.58,2.69,1.52,3.86,1.44,1.84-.11,4.23-.38,5.28-1.57s1.06-3.69.45-5.2c-4.05-10.09-8.4-20.06-12.67-30.12,9.21-1.93,9.43-1.8,12.83,6.49,1.93,4.71,3.95,9.39,6.23,14.77,2.22-5.36,4.15-10,6.09-14.67,3.2-7.65,3.21-7.64,12.43-7-3.73,8.92-7.36,17.65-11,26.37-1.61,3.81-2.83,7.88-5.07,11.27-2,3-5.16,5.28-7.81,7.87Z"
          transform="translate(-354.18 -214.31)"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M575.45,247.75H627l.78,1.22c-8.1,7.42-15.57,15.73-24.53,21.91C598,274.45,590.53,275.55,584,276c-11.07.8-22.25.08-33.37.33-2.43.06-4.82,1.5-7.23,2.3l.39,1.74h51.31a43.44,43.44,0,0,1-2.81,3.72c-4.88,5-9.77,9.94-14.75,14.82a35.33,35.33,0,0,1-25.09,10.31c-6,.08-12,.13-17.94,0A17.72,17.72,0,0,0,520.69,315c-4.38,4.5-8.78,9-14,14.27-4.4-4.69-8.5-9-12.49-13.34a19.86,19.86,0,0,0-15.88-6.73,207.86,207.86,0,0,1-23.81-.92c-3.65-.37-7.79-1.61-10.56-3.87-8.64-7-16.73-14.76-25-22.22l.82-1.44H470.2l.51-1.78c-2.32-.9-4.62-2.51-7-2.57-11-.27-21.92-.16-32.89-.11-11.35.06-21-3.74-28.94-12-5.05-5.23-10.62-10-16-14.92l.87-1.5h52.26c-2-4.06-4.61-5.05-8.29-5-12.46.15-25,.6-37.35-.31-6-.44-12.71-2.4-17.46-5.9-8.13-6-15-13.75-21.81-21.84H359c28.07,0,56.14.08,84.21,0a34.92,34.92,0,0,1,25.5,10.38c4.28,4.17,8.2,8.75,12.78,12.54a10.51,10.51,0,0,0,7.78,2.16c1.35-.27,2.32-4.06,2.85-6.41.68-3,.32-6.32,1.15-9.29a12.87,12.87,0,0,1,12.59-9.37c7.14-.07,14.28-.06,21.43,0,4.75,0,5.66,1,6.54,6.52-5.92.84-10.66,3.28-11.49,10-.49,3.94.25,7.46,4.14,9.2,3.72,1.66,6.88.27,9-3.13,11-18.14,27.09-24.08,48.07-22.91,25.32,1.41,50.79.33,77.48.33-13.83,13.29-24,29.87-46,28.26-10.57-.78-21.25-.32-31.87,0-2.75.07-5.46,1.66-8.19,2.56Z"
          transform="translate(-354.18 -214.31)"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M423.06,360.4v42.18h-9V380.09l-1.26-.39q-4.67,7.64-9.33,15.25c-3.36,5.46-5.67,5.42-9.15-.12L384.37,379v23.45h-9.68V360.53c5.54-1.6,9.09-.13,11.79,5,3.65,6.95,8.12,13.48,12.57,20.73l12.35-20.4C415.15,359.68,415.16,359.68,423.06,360.4Z"
          transform="translate(-354.18 -214.31)"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M561.86,403.85c-14.42,0-24-9-24-22.59,0-13.19,9.72-22.18,23.88-22.19,14.42,0,24,9,24,22.6C585.7,394.85,576,403.85,561.86,403.85Zm.18-36c-8.21-.07-14,5.41-14.17,13.4s5.63,13.78,13.71,13.86,14-5.39,14.18-13.39S570.13,367.91,562,367.83Z"
          transform="translate(-354.18 -214.31)"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M637.29,384.89H618v9.93h23v7.61H607.94v-42h32.24v7.39H618v9h19.27Z"
          transform="translate(-354.18 -214.31)"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M517.78,378.87v8h-19.6v15.61h-9.8v-42.1h32.29v7.5H498.31v11Z"
          transform="translate(-354.18 -214.31)"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M528.64,403.93c-3.56-.46-5.67-2.34-5.68-6.14s2.58-6.13,6.39-5.86,5.69,2.48,5.51,6.23S532.39,403.69,528.64,403.93Z"
          transform="translate(-354.18 -214.31)"
          fill={PRIMARY_MAIN}
        />
        <path
          d="M600.76,397.88c-.31,3.53-2,5.78-5.86,5.95a5.68,5.68,0,0,1-6.15-6.15c.13-3.82,2.23-5.75,6-5.74S600.46,394.06,600.76,397.88Z"
          transform="translate(-354.18 -214.31)"
          fill={PRIMARY_MAIN}
        />
      </svg>
    </Box>
  );
}
