import { sample } from 'lodash';
// utils
import mock from './mock';
import mockData from '../utils/mock-data';

// ----------------------------------------------------------------------

mock.onGet('/api/clients/all').reply(() => {
  const clients = [...Array(24)].map((_, index) => ({
    id: mockData.id(index),
    avatarUrl: mockData.image.avatar(index),
    name: mockData.client.clientName(index)
  }));

  return [200, { clients }];
});

mock.onGet('/api/clients/manage-clients').reply(() => {
  const clients = [...Array(24)].map((_, index) => ({
    id: mockData.id(index),
    avatarUrl: mockData.image.schoolLogos(index),
    name: mockData.client.name(index),
    application: mockData.application(index),
    schoolType: mockData.schoolType(index),
    status: sample(['active', 'inactive']) || 'active',
    role: mockData.role(index)
  }));

  return [200, { clients }];
});
