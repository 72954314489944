import { subMonths } from 'date-fns';
//
import { role } from './role';
import { email } from './email';
import { boolean } from './boolean';
import { company } from './company';
import { phoneNumber } from './phoneNumber';
import { fullAddress, country } from './address';
import { firstName, lastName, fullName } from './name';
import { title, sentence, description } from './text';
import { rating, age, percent } from './number';
import { clientName, schoolType, application } from './client';

// ----------------------------------------------------------------------

const mockData = {
  id: (index) => `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${index + 1}`,
  email: (index) => email[index],
  phoneNumber: (index) => phoneNumber[index],
  time: (index) => subMonths(new Date(new Date().getFullYear(), new Date().getMonth(), 1), index),
  boolean: (index) => boolean[index],
  role: (index) => role[index],
  company: (index) => company[index],
  schoolType: (index) => schoolType[index],
  application: (index) => application[index],
  address: {
    fullAddress: (index) => fullAddress[index],
    country: (index) => country[index]
  },
  name: {
    firstName: (index) => firstName[index],
    lastName: (index) => lastName[index],
    fullName: (index) => fullName[index]
  },
  client: {
    name: (index) => clientName[index]
  },
  text: {
    title: (index) => title[index],
    sentence: (index) => sentence[index],
    description: (index) => description[index]
  },
  number: {
    percent: (index) => percent[index],
    rating: (index) => rating[index],
    age: (index) => age[index],
    price: () => 250
  },
  image: {
    cover: (index) => `/static/mock-images/covers/cover_${index + 1}.jpg`,
    feed: (index) => `/static/mock-images/feeds/feed_${index + 1}.jpg`,
    product: (index) => `/static/mock-images/products/product_${index + 1}.jpg`,
    avatar: (index) => `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
    application: (index) => `/static/mock-images/apps/app_${index + 1}.png`,
    schoolLogos: (index) => `/static/mock-images/logos/logo_${index + 1}.png`
  }
};

export default mockData;
