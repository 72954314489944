// ----------------------------------------------------------------------

export const clientName = [
  'East Shores School',
  'Redwood Elementary',
  'Saint Marys Charter',
  'Evergreen School for Boys',
  'Coral Springs School for Girls',
  'Ravenwood Institute',
  'Waterford High School',
  'Maple Leaf High School',
  'Central Valley',
  'Copper Cove High School',
  'Hercules High School',
  'Eagle Mountain High School',
  'River Valley School for Girls',
  'Canyon View Secondary School',
  'Northride Academy',
  'Golden Sierra High School',
  'Ocean View High School',
  'Bayshore High School',
  'Eastwood Institute',
  'Big Valley Elementary',
  'Savanna Middle School',
  'Whitewater Academy',
  'Oak Park High School',
  'Summit Middle School'
];

export const schoolType = [
  'School',
  'Elementary',
  'School',
  'School for Boys',
  'Institute',
  'School for Girls',
  'Grammar School',
  'High School',
  'University',
  'Academy',
  'Secondary School',
  'Middle School',
  'School',
  'Elementary',
  'School',
  'School for Boys',
  'Institute',
  'School for Girls',
  'Grammar School',
  'High School',
  'University',
  'Academy',
  'Secondary School',
  'Middle School'
];

export const application = [
  'My East Shores',
  'My Redwood',
  'My Saint Marys',
  'My Evergreen',
  'My Coral Springs',
  'My Ravenwood',
  'My Waterford',
  'My Maple Leaf',
  'My Central Valley',
  'My Copper Cove ',
  'My Hercules',
  'My Eagle Mountain',
  'My River Valley',
  'My Canyon View',
  'My Northride ',
  'My Golden Sierra',
  'My Ocean View',
  'My Bayshore ',
  'My Eastwood',
  'My Big Valley ',
  'My Savanna',
  'My Whitewater',
  'My Oak Park',
  'My Summit'
];
