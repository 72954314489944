import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
// import Preview from './components/preview';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
// import Settings from './components/settings';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized, user, isAuthenticated } = useAuth();
  // const { pathname } = useLocation();
  // const isClientAdmin = pathname.includes('client-admin');
  const [initalized, setInitialized] = useState(false);

  useEffect(() => {
    if (user?.activeClient && isInitialized) {
      setInitialized(true);
    }
    if (!isAuthenticated && isInitialized) {
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isInitialized, isAuthenticated]);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <ProgressBarStyle />
              <BaseOptionChartStyle />
              {/* {isClientAdmin && <Preview />} */}
              {/* <Settings /> */}
              <ScrollToTop />
              <GoogleAnalytics />
              {initalized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
