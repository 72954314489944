import { cloneDeep } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { extname } from 'path';
// Firebase
import { firebase } from '../../config/firebase';

const storage = firebase.storage();

const initialState = {
  isLoading: false,
  progress: 0,
  error: false,
  mediaItems: [],
  files: [],
  selectedMediaItems: [],
  media: null
};

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START UPLOADING
    startUploading(state, action) {
      state.progress = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MEDIAS
    getMediaItemsSuccess(state, action) {
      state.isLoading = false;
      state.mediaItems = action.payload;
    },

    // GET FILES
    getFilesSuccess(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    // // DELETE MEDIA
    deleteMediaSuccess(state, action) {
      const { name } = action.payload;
      state.isLoading = false;
      state.mediaItems = state.mediaItems.filter((item) => item.name !== name);
    },

    // SET SELECTED FILES
    onSetSelectedMediaItemsSuccess(state, action) {
      state.selectedMediaItems = action.payload;
    },

    // UPDATE MEDIA ITEMS AFTER UPLOAD
    updateMediaItems(state, action) {
      const newFile = action.payload;
      state.mediaItems = [...state.mediaItems, newFile];
    },

    // UPDATE MEDIA ITEMS AFTER UPLOAD
    updateFileItems(state, action) {
      const newFile = action.payload;
      state.files = [...state.files, newFile];
    }
  }
});

// Reducer
export default slice.reducer;

export const { updateMediaItems, updateFileItems } = slice.actions;

// deleteMediaItems in storage-----------------------//
export function deleteMedia(file) {
  return async (dispatch, getState) => {
    const { client } = cloneDeep(getState());
    const { name } = file;
    try {
      const originalFileName = name.split('_')[1];
      const originalExt = extname(name);
      const mediaRef = storage.ref(`clients/${client.currentClient.id}/media/${originalFileName}${originalExt}`);
      const mediaThumbnailRef = storage.ref(`clients/${client.currentClient.id}/media/thumbnails/${name}`);

      // Delete both full res and thumbnail files
      await Promise.all([mediaThumbnailRef.delete(), mediaRef.delete()]);
      dispatch(slice.actions.deleteMediaSuccess(file));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// getMediaItems in storage-----------------------//
/* eslint-disable */

export function getMediaItems() {
  return async (dispatch, getState) => {
    const { client } = cloneDeep(getState());
    dispatch(slice.actions.startLoading());
    try {
      const storageRef = storage.ref();
      const imagesRef = storageRef.child(`clients/${client.currentClient.id}/media/thumbnails`);
      // I need a pdf ref
      const list = await imagesRef.listAll();
      let fileReads = [];
      if (list.items.length >= 1) {
        fileReads = list.items.map(async (fileRef) => {
          const metaData = await fileRef.getMetadata();
          const downloadUrl = await fileRef.getDownloadURL();
          return { ...metaData, downloadUrl };
        });
      }
      const result = await Promise.all(fileReads);
      dispatch(slice.actions.getMediaItemsSuccess(result));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// getFileItems in storage-----------------------
export function getFileItems() {
  return async (dispatch, getState) => {
    const { client } = cloneDeep(getState());
    dispatch(slice.actions.startLoading());
    try {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`clients/${client.currentClient.id}/media/files`);
      const fileList = await fileRef.listAll();
      let fileReads = [];
      if (fileList.items.length >= 1) {
        fileReads = fileList.items.map(async (fileRef) => {
          const metaData = await fileRef.getMetadata();
          const downloadUrl = await fileRef.getDownloadURL();
          return { ...metaData, downloadUrl };
        });
      }
      const result = await Promise.all(fileReads);
      dispatch(slice.actions.getFilesSuccess(result));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function setSelectedMediaItems(selectedFiles) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.onSetSelectedMediaItemsSuccess(selectedFiles));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
